import { getProfitLevel } from "../../services/utils";

export default function ClosedTrades({ account, tradesHistory }) {
  return (
    <div className="content-table">
      <table>
        <thead>
          <tr>
            <td>Ticket</td>
            <td>Type</td>
            <td>Symbol</td>
            <td>Lots</td>
            <td>Close Time</td>
            <td>Profit</td>
            <td>Commission</td>
            <td>Swap</td>
          </tr>
        </thead>
        <tbody>
          {account &&
            tradesHistory &&
            tradesHistory.reverse().map((trade, i) => (
              <tr key={i}>
                <td>{trade.orderTicket}</td>
                <td>{trade.type}</td>
                <td>{trade.symbol}</td>
                <td>{trade.lots}</td>
                <td>{trade.closeTime}</td>
                <td className={getProfitLevel(trade.profit)}>
                  {parseInt(trade.profit)} {account.currency}
                </td>
                <td>
                  {trade.commission} {account.currency}
                </td>
                <td>
                  {trade.swap} {account.currency}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
