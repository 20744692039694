export const getUrlPost = function (post) {
  if (post) return process.env.REACT_APP_APP_URL + "/blog/" + post._id + "/" + post.urlPost;
};

export const getProfitLevel = (level) => {
  if (level > 0) {
    return "level_safe";
  } else {
    return "level_danger";
  }
};

export const getProfitPercentageLevel = (level) => {
  if (level >= 0.3) {
    return "level_safe";
  } else if (level < 0) {
    return "level_danger";
  }
};

export const getDDPercentageLevel = (level) => {
  if (level >= 0.3) {
    return "level_danger";
  } else if (level < 0) {
    return "level_safe";
  }
};

export const getRiskLevel = (level) => {
  if (level < 75) {
    return "level_safe";
  } else if (level < 95) {
    return "level_warn";
  } else {
    return "level_danger";
  }
};
