import { useEffect, useState } from "react";
import { getProfitPercentageLevel, getRiskLevel } from "../../services/utils";
import api from "../../services/api";

export default function DailyOverview({ account, recalculateProfit }) {
  const [dailyOverview, setDailyOverview] = useState([]);

  useEffect(() => {
    // Set daily overview
    let daily = [];
    for (let i in account.dailyProfitHistory) {
      daily.push({
        date: i,
        profit: account.dailyProfitHistory[i],
        dailyLoss: account.dailyLossHistory[i],
      });
    }

    setDailyOverview(daily);
  }, [account]);

  return (
    <div className="content-table">
      <table>
        <thead>
          <tr>
            <td style={{ textAlign: "left" }}>Date</td>
            <td>Profit</td>
            <td>Daily Loss</td>
          </tr>
        </thead>
        <tbody>
          {dailyOverview &&
            dailyOverview.map((trade, i) => (
              <tr key={i}>
                <td style={{ textAlign: "left" }}>{trade.date}</td>
                <td>
                  <div className={"cols-2 " + getProfitPercentageLevel((trade.profit / account.accountSize) * 100)}>
                    <div>({parseFloat((trade.profit / account.accountSize) * 100).toFixed(1)}%)</div>
                    <div>
                      <b>
                        {parseInt(trade.profit)} {account.currency}
                      </b>
                    </div>
                  </div>
                </td>
                <td>
                  <div className={"cols-2 " + getRiskLevel(-1 * (trade.dailyLoss / account.maxDailyLoss) * 100)}>
                    {trade.dailyLoss && (
                      <>
                        <div>({parseFloat(-1 * (trade.dailyLoss / account.accountSize) * 100).toFixed(1)}%)</div>
                        <div>
                          <b>
                            {parseInt(trade.dailyLoss)} {account.currency}
                          </b>
                        </div>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <button style={{ marginTop: 20 }} onClick={recalculateProfit}>
        Recalculate Profit
      </button>
    </div>
  );
}
