import MultiProgress from "react-multi-progress";
import { Link } from "react-router-dom";

export default function TableAccounts({ accounts }) {
  const getRiskLevel = (level) => {
    if (level < 75) {
      return "level_safe";
    } else if (level < 95) {
      return "level_warn";
    } else {
      return "level_danger";
    }
  };

  const isValidAccount = (dailyLoss, profit) => {
    if (dailyLoss === "level_safe" && profit !== "level_danger") {
      return "level_safe";
    }
    if (dailyLoss === "level_warn" && profit !== "level_danger") {
      return "level_warn";
    }

    return "level_danger";
  };

  const getProfitLevel = (level) => {
    if (level >= 0.3) {
      return "level_safe";
    } else if (level < 0) {
      return "level_danger";
    }
  };

  const getRiskLevelColor = (level) => {
    if (level < 75) {
      return "green";
    } else if (level < 95) {
      return "orange";
    } else {
      return "red";
    }
  };

  const isActive = (account) => {
    if (account && account.dailyProfitHistory) {
      let lastSum = Object.values(account.dailyProfitHistory || {})
        .splice(-4)
        .reduce((prev, curr) => prev + curr);

      if (lastSum === 0) {
        return "unactive";
      } else if (lastSum > 0) {
        return "active";
      } else {
        return "losing";
      }
    }

    return "unactive";
  };

  return (
    <div>
      {accounts && (
        <>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Account</td>
                  <td>Drawdown</td>
                  <td>Profit</td>
                  <td>Balance</td>
                  <td style={{ minWidth: 200 }}>Daily Loss</td>
                  <td>Average</td>
                  <td>Valid</td>
                </tr>
              </thead>
              <tbody>
                {accounts.map((account, i) => (
                  <tr key={i}>
                    <td>
                      <Link to={"/account/" + account._id}>
                        <div className={isActive(account)}>{account.title}</div>
                        <div className="meta">{account.accountNumber}</div>
                      </Link>
                    </td>
                    <td>
                      <div className="cols-2">
                        <div>({parseInt((account.drawdown / account.balance) * 100)} %)</div>
                        <div>
                          {parseInt(account.drawdown)} {account.currency}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"cols-2 " + getProfitLevel((account.dailyProfitCurrent / account.startOfDayBalance) * 100)}>
                        <div>({parseFloat((account.dailyProfitCurrent / account.startOfDayBalance) * 100).toFixed(1)}%)</div>
                        <div>
                          <b>
                            {parseInt(account.dailyProfitCurrent)} {account.currency}
                          </b>
                        </div>
                      </div>
                    </td>

                    <td>
                      <b>
                        {parseInt(account.balance)} {account.currency}
                      </b>
                    </td>
                    <td style={{ textAlign: "left" }}>
                      <div className="form-list mb-10">
                        <div>
                          <label>Current Daily Loss</label>
                          <div className={"value " + getRiskLevel(-1 * (account.dailyLossCurrent / account.maxDailyLoss) * 100)}>
                            <div className="abs">
                              {parseInt(account.dailyLossCurrent)} {account.currency}
                            </div>
                            <div className="perc">
                              {parseFloat(-1 * (account.dailyLossCurrent / account.accountSize) * 100).toFixed(1)}%
                            </div>
                          </div>
                        </div>
                        <div>
                          <label>Max Daily Loss</label>
                          <div className={"value " + getRiskLevel(-1 * (account.maxDailyLossToday / account.maxDailyLoss) * 100)}>
                            <div className="abs">
                              {parseInt(account.maxDailyLossToday)} {account.currency}
                            </div>
                            <div className="perc">
                              {parseFloat(-1 * (account.maxDailyLossToday / account.accountSize) * 100).toFixed(1)}%
                            </div>
                          </div>
                        </div>

                        <div>
                          <label>Max Allowed Daily Loss</label>
                          <div className="value">
                            <div className="abs">
                              {parseInt(account.maxDailyLoss)} {account.currency}
                            </div>
                            <div className="perc">{account.maxDailyLossPercentage} %</div>
                          </div>
                        </div>
                      </div>

                      <MultiProgress
                        height={15}
                        backgroundColor="#cacaca"
                        elements={[
                          {
                            value: parseInt(-1 * (account.dailyLossCurrent / account.maxDailyLoss) * 100),
                            color: getRiskLevelColor(-1 * (account.dailyLossCurrent / account.maxDailyLoss) * 100),
                            showPercentage: true,
                            isBold: true,
                            textColor: "white",
                            fontSize: 12,
                          },
                          {
                            value: parseInt(
                              -1 * ((account.maxDailyLossToday - account.dailyLossCurrent) / account.maxDailyLoss) * 100
                            ),
                            color: "#aaa",
                            className: "max-bar",
                          },
                        ]}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div className="form-list">
                        <div>
                          <label>Daily Loss</label>
                          <div className={"value " + getRiskLevel(-1 * (account.dailyLossAvg / account.maxDailyLoss) * 100)}>
                            <div className="abs">
                              {parseInt(account.dailyLossAvg)} {account.currency}
                            </div>
                            <div className="perc">
                              {parseFloat(-1 * (account.dailyLossAvg / account.accountSize) * 100).toFixed(1)}%
                            </div>
                          </div>
                        </div>

                        <div>
                          <label>Max Daily Loss</label>
                          <div className={"value " + getRiskLevel(-1 * (account.maxDailyLossAbs / account.maxDailyLoss) * 100)}>
                            <div className="abs">
                              {parseInt(account.maxDailyLossAbs)} {account.currency}
                            </div>
                            <div className="perc">
                              {parseFloat(-1 * (account.maxDailyLossAbs / account.accountSize) * 100).toFixed(1)}%
                            </div>
                          </div>
                        </div>

                        <div>
                          <label>Profit</label>
                          <div className={"value " + getProfitLevel((account.dailyProfitAvg / account.startOfDayBalance) * 100)}>
                            <div className="abs">
                              {parseInt(account.dailyProfitAvg)} {account.currency}
                            </div>
                            <div className="perc">
                              {parseFloat((account.dailyProfitAvg / account.startOfDayBalance) * 100).toFixed(1)}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "dot " +
                          isValidAccount(
                            getRiskLevel(-1 * (account.maxDailyLossAbs / account.maxDailyLoss) * 100),
                            getProfitLevel((account.dailyProfitAvg / account.startOfDayBalance) * 100)
                          )
                        }
                      ></div>
                    </td>
                  </tr>
                ))}

                {accounts.length === 0 && (
                  <tr>
                    <td colSpan="2">
                      <div style={{ padding: "5px 15px" }}>No Accounts Found</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
