import { useState } from "react";
import { Helmet } from "react-helmet";

// Context
import { useAuth } from "../context/useAuth";
import { Link } from "react-router-dom";

const TITLE = "MT Monitor - App";

export default function Submit() {
  let auth = useAuth();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginFn = async () => {
    if (!(await auth.login(username, password))) setError(true);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="login-container">
        <h1>MT Monitor</h1>
        <h3>Login into your account</h3>
        <div className="login-form">
          <input onChange={(e) => setUsername(e.target.value)} placeholder="username"></input>
          <input onChange={(e) => setPassword(e.target.value)} placeholder="password" type="password"></input>
        </div>
        <button className="big" onClick={() => loginFn()}>
          Login
        </button>

        {error && <div className="error mt-15">Login not valid</div>}

        <Link className="mt-15" to="/resetPassword">
          Reset Password
        </Link>
      </div>
    </>
  );
}
