import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 300000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  const login = (email, password) => {
    return api.post(`/api/users/login`, { email, password });
  };

  const resetPassword = (userId, token, password) => {
    return api.post(`/api/users/resetPassword`, { userId, token, password });
  };

  const resetPasswordRequest = (email) => {
    return api.post(`/api/users/resetPasswordRequest`, { email });
  };

  const getUser = () => {
    return api.get(`/api/users/getUser`, {});
  };

  const getAccounts = () => {
    return api.get(`/api/accounts`);
  };

  const getAccountData = (id) => {
    return api.post(`/api/accounts/${id}/data`);
  };

  const setAccountType = (id, type) => {
    return api.post(`/api/accounts/${id}`, { type });
  };

  const reset = (id) => {
    return api.post(`/api/accounts/${id}/reset`, {});
  };

  const getAccountMonitor = (id, timeSelected) => {
    return api.post(`/api/accounts/${id}/monitor`, { timeSelected });
  };

  const getTradesHistory = (id) => {
    return api.post(`/api/accounts/${id}/getTradesHistory`);
  };

  const recalculateProfit = (id) => {
    return api.post(`/api/accounts/${id}/recalculateProfit`);
  };

  // return functions for interface
  return {
    setToken,
    getAccounts,
    login,
    resetPassword,
    resetPasswordRequest,
    getUser,
    getAccountData,
    getAccountMonitor,
    getTradesHistory,
    setAccountType,
    reset,
    recalculateProfit,
  };
};

// Creates a singleton
export default createApi();
