import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// Components
import HeaderAdmin from "../components/HeaderAdmin";
import BestPairs from "./accountView/BestPairs";
import ClosedTrades from "./accountView/ClosedTrades";

// Image
import backArrow from "../images/back_arrow.svg";
import OpenTrades from "./accountView/OpenTrades";
import DailyOverview from "./accountView/DailyOverview";
import AccountInfo from "./accountView/AccountInfo";
import Chart from "./accountView/Chart";
import api from "../services/api";

export default function AccountView() {
  const [showOperationClosed, setShowOperationClosed] = useState(false);
  const [showOperationOpen, setShowOperationOpen] = useState(true);
  const [showBestPair, setShowBestPair] = useState(true);
  const [showDailyOverview, setShowDailyOverview] = useState(true);
  const [account, setAccount] = useState({});
  const [tradesHistory, setTradesHistory] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
    getTradesHistory();
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    let account = await api.getAccountData(id);
    if (account.status === 200) {
      account = account.data;
      setAccount(account);
    }
  };

  const getTradesHistory = async () => {
    let account = await api.getTradesHistory(id);
    if (account.status === 200) {
      setTradesHistory(account.data.tradesHistory);
    }
  };

  const recalculateProfit = async () => {
    let accountData = await api.recalculateProfit(id);
    if (accountData.status === 200) {
      await fetchData();
      alert("Data Updated");
    }
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="edit-post-container">
        <h2>
          <Link to="/" className="back-link">
            <img style={{ height: 30 }} src={backArrow} alt="back" />
          </Link>
          Account {account.title}
        </h2>

        <Chart account={account}></Chart>

        {account.balance ? (
          <div>
            <h2>Account info</h2>
            <AccountInfo account={account} setAccount={setAccount}></AccountInfo>

            <h2 onClick={() => setShowDailyOverview(!showDailyOverview)}>Daily Overview</h2>
            {showDailyOverview && <DailyOverview recalculateProfit={recalculateProfit} account={account}></DailyOverview>}

            <h2 onClick={() => setShowOperationOpen(!showOperationOpen)}>Operations Open</h2>
            {showOperationOpen && <OpenTrades account={account}></OpenTrades>}

            <h2 onClick={() => setShowOperationClosed(!showOperationClosed)}>Operations Closed</h2>
            {showOperationClosed && <ClosedTrades account={account} tradesHistory={tradesHistory}></ClosedTrades>}

            <h2 onClick={() => setShowBestPair(!showBestPair)}>Best Pairs</h2>
            {showBestPair && <BestPairs account={account} tradesHistory={tradesHistory}></BestPairs>}
          </div>
        ) : (
          "Loading data"
        )}
      </div>
    </>
  );
}
