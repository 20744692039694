import { useEffect, useState } from "react";

// Components
import HeaderAdmin from "../components/HeaderAdmin";

// Api
import api from "../services/api";
import TableAccounts from "./accountView/TableAccounts";

export default function AdminBlog() {
  const [accounts, setAccounts] = useState();

  useEffect(() => {
    fetchAccounts();
    const interval = setInterval(fetchAccounts, 10000);

    // Clearing the interval
    return () => clearInterval(interval);
  }, []);

  const fetchAccounts = async () => {
    let posts = await api.getAccounts();
    if (posts.status === 200) setAccounts(posts.data.reverse());
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>Accounts Live</h2>
        {accounts && (
          <>
            <TableAccounts accounts={accounts.filter((a) => a.type === "Live")}></TableAccounts>
            <h2>Accounts Demo</h2>
            <TableAccounts accounts={accounts.filter((a) => a.type === "Demo" || a.type === "" || !a.type)}></TableAccounts>
            <h2>Accounts Archived</h2>
            <TableAccounts accounts={accounts.filter((a) => a.type === "Archived")}></TableAccounts>
          </>
        )}
      </div>
    </>
  );
}
