import { getRiskLevel } from "../../services/utils";
import moment from "moment";

// Api
import api from "../../services/api";
import { useState } from "react";

export default function AccountInfo({ account, setAccount }) {
  const [type, setType] = useState(account.type);

  const reset = async () => {
    let data = await api.reset(account._id);
    if (data.status === 200) {
      alert("Account reset");
    } else {
      alert("Reset Failed");
    }
  };

  const setAccountType = async (e) => {
    const type = e.target.value;
    setType(type);
    await api.setAccountType(account._id, type);
  };

  return (
    <div>
      <div className="label-row">
        <div className="form-list">
          <div>
            <label>Account Type</label>
            <select value={type} onChange={(e) => setAccountType(e)}>
              <option value="Demo">Demo</option>
              <option value="Live">Live</option>
              <option value="Archived">Archived</option>
            </select>
          </div>

          <div>
            <label>Account number</label>
            {account.accountNumber}
          </div>

          <div>
            <label>Current time</label>
            {moment(account.currentTime).utc().format("D MMM HH:mm")}
          </div>

          <div>
            <label>Balance</label>
            {account.balance} {account.currency}
          </div>

          <div>
            <label>Drawdown</label>
            {account.drawdown} {account.currency}
          </div>

          <div>
            <label>Drawdown %</label>
            {parseInt((account.drawdown / account.balance) * 100)} %
          </div>

          <div>
            <label>Max Allowed Daily Loss</label>
            {parseInt(account.maxDailyLoss)} {account.currency}
          </div>

          <div>
            <label>Max Allowed Daily Loss %</label>
            {parseInt(account.maxDailyLossPercentage)} %
          </div>

          <h4>Daily</h4>
          <div>
            <label>Current Daily Loss</label>
            <div className={"value " + getRiskLevel(-1 * (account.dailyLossCurrent / account.maxDailyLoss) * 100)}>
              <div className="abs">
                {parseInt(account.dailyLossCurrent)} {account.currency}
              </div>
              <div className="perc">{parseFloat(-1 * (account.dailyLossCurrent / account.accountSize) * 100).toFixed(1)}%</div>
            </div>
          </div>

          <div>
            <label>Max Daily Loss</label>
            <div className={"value " + getRiskLevel(-1 * (account.maxDailyLossToday / account.maxDailyLoss) * 100)}>
              <div className="abs">
                {parseInt(account.maxDailyLossToday)} {account.currency}
              </div>
              <div className="perc">{parseFloat(-1 * (account.maxDailyLossToday / account.accountSize) * 100).toFixed(1)}%</div>
            </div>
          </div>
          <div>
            <label>Profit</label>
            <div className="value">
              <div className="abs">
                {parseInt(account.dailyProfitCurrent)} {account.currency}
              </div>
              <div className="perc">{parseFloat((account.dailyProfitCurrent / account.startOfDayBalance) * 100).toFixed(1)}%</div>
            </div>
          </div>

          <h4>Average</h4>

          <div>
            <label>Daily Loss</label>
            <div className={"value " + getRiskLevel(-1 * (account.dailyLossAvg / account.maxDailyLoss) * 100)}>
              <div className="abs">
                {parseInt(account.dailyLossAvg)} {account.currency}
              </div>
              <div className="perc">{parseFloat(-1 * (account.dailyLossAvg / account.accountSize) * 100).toFixed(1)}%</div>
            </div>
          </div>

          <div>
            <label>Max Daily Loss</label>
            <div className={"value " + getRiskLevel(-1 * (account.maxDailyLossAbs / account.maxDailyLoss) * 100)}>
              <div className="abs">
                {parseInt(account.maxDailyLossAbs)} {account.currency}
              </div>
              <div className="perc">{parseFloat(-1 * (account.maxDailyLossAbs / account.accountSize) * 100).toFixed(1)}%</div>
            </div>
          </div>

          <div>
            <label>Profit</label>
            <div className="value">
              <div className="abs">
                {parseInt(account.dailyProfitAvg)} {account.currency}
              </div>
              <div className="perc">{parseFloat((account.dailyProfitAvg / account.startOfDayBalance) * 100).toFixed(1)}%</div>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <button className="btn small" onClick={() => reset()}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
