import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Libs
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";

// Utils
import moment from "moment";

// Api
import api from "../../services/api";

export default function ChartComponent({ account }) {
  const { id } = useParams();
  const [chart, setChart] = useState();
  const [monitorsData, setMonitorsData] = useState();
  const [loading, setLoading] = useState(false);
  const [timeSelected, setTimeSelected] = useState("hours");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id, timeSelected]);

  const fetchData = async () => {
    setLoading(true);
    let accountMonitor = await api.getAccountMonitor(id, timeSelected);
    if (accountMonitor.status === 200) {
      setMonitorsData(accountMonitor.data);
    }
  };

  useEffect(() => {
    if (!monitorsData || !account._id) {
      return;
    }
    let annotations = monitorsData.annotations;
    let monitors = monitorsData.monitors;
    let dates = [];
    let profits = [];
    let balance = [];
    let drawdown = [];
    let dailyLoss = [];
    let options;

    if (timeSelected === "days") {
      setLoading(false);

      for (let date in account.dailyLossHistory) {
        profits.push(account.dailyProfitHistory[date]);
        dailyLoss.push(account.dailyLossHistory[date]);
        dates.push(moment(date, "DD/MM/YYYY").toString());
      }

      options = {
        type: "bar",
        series: [
          {
            name: "Profit",
            data: profits,
            color: "#66b8f2",
            type: "line",
          },
          {
            name: "Daily Loss",
            data: dailyLoss,
            color: "#ff4560",
            type: "column",
          },
        ],
        options: {
          plotOptions: {
            bar: {
              columnWidth: "40%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: [3, 0],
          },
          yaxis: {
            title: {
              text: "Daily Loss",
            },
            labels: {
              formatter: function (y) {
                return ((y / account.accountSize) * 100).toFixed(1) + "%";
              },
            },
          },
          xaxis: {
            type: "datetime",
            categories: dates,
            labels: {
              rotate: -90,
            },
          },
        },
        annotations: {
          xaxis: [],
          yaxis: [
            {
              y: -1 * account.maxDailyLoss,
              borderColor: "#000",
              fillColor: "#FEB019",
              text: "Max Daily Loss",
            },

            {
              y: account.balance / 100,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text: "1% profit",
              },
            },
          ],
        },
        height: 400,
      };

      setChart(options);

      setTimeout(() => {
        let chart = ApexCharts.getChartByID("chart");
        if (chart != null) chart.removeAnnotation("area-loss");
      }, 1000);
    } else {
      for (let m of monitors) {
        balance.push(m.balance - account.accountSize);
        drawdown.push(m.drawdown);
        profits.push(m.currentDailyProfit);
        dailyLoss.push(m.currentDailyDD);
        dates.push(m.date);
      }

      options = {
        type: "line",
        series: [
          {
            name: "Balance",
            data: balance,
          },
          {
            name: "Drawdown",
            data: drawdown,
          },
          {
            name: "Profits",
            data: profits,
          },
          {
            name: "Daily Loss",
            data: dailyLoss,
          },
        ],

        options: {
          stroke: {
            show: true,
            width: 5,
          },
          yaxis: {
            labels: {
              formatter: function (y, e) {
                if (e && e.w) {
                  return parseInt(y) + account.currency;
                } else {
                  return ((y / account.accountSize) * 100).toFixed(1) + "%";
                }
              },
            },
          },
          xaxis: {
            type: "datetime",
            categories: dates,
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "MMM 'yy",
                day: "dd MMM",
                hour: "HH:mm",
              },
              formatter: function (date, a, e) {
                if (e && e.i === undefined) {
                  return moment(date).utc().format("D MMM HH:mm");
                } else {
                  return moment(date).utc().format("D MMM");
                }
              },
            },
          },
          chart: {
            id: "chart",
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "pan",
          },
          colors: ["#008ffb", "#feb019", "#00e396", "#ff4560"],
          annotations: {
            xaxis: [],
            yaxis: [
              {
                id: "area-loss",
                y: -1 * account.maxDailyLoss,
                y2: 0,
                borderColor: "#000",
                opacity: 0.1,
                fillColor: "#FEB019",
              },

              {
                y: account.balance / 100,
                borderColor: "#00E396",
                label: {
                  borderColor: "#00E396",
                  style: {
                    color: "#fff",
                    background: "#00E396",
                  },
                  text: "1% profit",
                },
              },
            ],
          },
        },

        height: 400,
      };

      for (let a of annotations) {
        options.options.annotations.xaxis.push({
          x: new Date(a.date).getTime(),
          borderColor: a.color,
          label: {
            borderColor: a.color,
            style: {
              color: "#fff",
              background: a.color,
            },
            text: a.title,
          },
        });
      }

      setChart(options);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [account, monitorsData]);

  return (
    <>
      <div className="btn-bar mt-30">
        <div className="btn-bar-left">
          <div className="btn-group">
            <button onClick={() => setTimeSelected("days")} className={"small " + (timeSelected === "days" ? "active" : "")}>
              DAY
            </button>
            <button onClick={() => setTimeSelected("hours")} className={"small " + (timeSelected === "hours" ? "active" : "")}>
              HOURS
            </button>
            <button
              onClick={() => setTimeSelected("minutes")}
              className={"small " + (timeSelected === "minutes" ? "active" : "")}
            >
              MINUTES
            </button>
          </div>
        </div>
      </div>
      {loading && <div>Loading Chart...</div>}

      {chart && <Chart {...chart} />}
    </>
  );
}
