import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { getProfitLevel, getDDPercentageLevel } from "../../services/utils";

export default function BestPairs({ account, tradesHistory }) {
  const [chartBestPair, setChartBestPair] = useState();
  const [bestPairs, setBestPairs] = useState([]);
  const [timeSelectedBest, setTimeSelectedBest] = useState("week");

  useEffect(() => {
    // Show best pairs chart
    let seriesMap = {};
    let series = [];

    for (let trade of tradesHistory) {
      if (trade.symbol) {
        if (!seriesMap[trade.symbol]) {
          seriesMap[trade.symbol] = {
            name: trade.symbol,
            data: {},
          };
        }

        let date = moment(trade.closeTime, "YYYY.MM.DD hh:mm").utc();
        if (!seriesMap[trade.symbol].data[date.format("D MMM")]) {
          seriesMap[trade.symbol].data[date.format("D MMM")] = {
            y: trade.profit,
            x: date.set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            }),
          };
        } else {
          seriesMap[trade.symbol].data[date.format("D MMM")].y += trade.profit;
        }
      }
    }

    series = Object.values(seriesMap);
    for (let s of series) {
      s.data = Object.values(s.data);
    }

    let options = {
      type: "line",
      series: series,
      options: {
        stroke: {
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        yaxis: {
          labels: {
            formatter: function (y, e) {
              if (e && e.w) {
                return parseInt(y) + account.currency;
              } else {
                return ((y / account.accountSize) * 100).toFixed(1) + "%";
              }
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function (date, a, e) {
              return moment(date).utc().format("D MMM");
            },
          },
        },
        chart: {
          id: "chart-best",
          animations: {
            enabled: false,
          },
        },
        colors: [
          "#e6194b",
          "#3cb44b",
          "#ffe119",
          "#4363d8",
          "#f58231",
          "#911eb4",
          "#46f0f0",
          "#f032e6",
          "#bcf60c",
          "#fabebe",
          "#008080",
          "#e6beff",
          "#9a6324",
          "#fffac8",
          "#800000",
          "#aaffc3",
          "#808000",
          "#ffd8b1",
          "#000075",
          "#808080",
          "#ffffff",
          "#000000",
        ],
      },

      height: 400,
    };

    setChartBestPair(options);
    // eslint-disable-next-line
  }, [tradesHistory]);

  useEffect(() => {
    // Set best pairs
    if (!tradesHistory || !account || tradesHistory.length === 0) {
      return;
    }

    let best = {};
    let now = new Date().getTime();

    let limit = 1000 * 60 * 60 * 24 * 365 * 10;

    if (timeSelectedBest === "week") {
      limit = 1000 * 60 * 60 * 24 * 7;
    }
    if (timeSelectedBest === "month") {
      limit = 1000 * 60 * 60 * 24 * 31;
    }

    for (let i of tradesHistory) {
      if (now - new Date(moment(i.closeTime, "YYYY.MM.DD hh:mm")).getTime() < limit) {
        if (!best[i.symbol]) {
          best[i.symbol] = {
            symbol: i.symbol,
            profit: 0,
            trades: 0,
            currentDD: 0,
            openTrades: 0,
          };
        }

        best[i.symbol] = {
          symbol: i.symbol,
          profit: best[i.symbol].profit + i.profit,
          trades: best[i.symbol].trades + 1,
          currentDD: 0,
          openTrades: 0,
        };
      }
    }

    for (let trade of account.openOrders) {
      if (!best[trade.symbol]) {
        best[trade.symbol] = {
          symbol: trade.symbol,
          profit: 0,
          trades: 0,
          currentDD: 0,
          openTrades: 0,
        };
      }

      best[trade.symbol].openTrades++;
      best[trade.symbol].currentDD += trade.profit;
    }

    setBestPairs(
      Object.values(best).sort((a, b) => {
        return a.profit > b.profit ? -1 : 1;
      })
    );
  }, [account, timeSelectedBest, tradesHistory]);

  return (
    <div>
      {chartBestPair && <Chart {...chartBestPair} />}
      <div className="btn-bar mt-30">
        <div className="btn-bar-left">
          <div className="btn-group">
            <button
              onClick={() => setTimeSelectedBest("week")}
              className={"small " + (timeSelectedBest === "week" ? "active" : "")}
            >
              1 Week
            </button>
            <button
              onClick={() => setTimeSelectedBest("month")}
              className={"small " + (timeSelectedBest === "month" ? "active" : "")}
            >
              1 Month
            </button>
            <button
              onClick={() => setTimeSelectedBest("all")}
              className={"small " + (timeSelectedBest === "all" ? "active" : "")}
            >
              ALL
            </button>
          </div>
        </div>
      </div>

      <div className="content-table">
        <table>
          <thead>
            <tr>
              <td style={{ textAlign: "left" }}>Symbol</td>
              <td>Profit</td>
              <td>Closed Trades</td>
              <td>Current DD</td>
              <td>Open Trades</td>
            </tr>
          </thead>
          <tbody>
            {bestPairs &&
              bestPairs.map((trade, i) => (
                <tr key={i}>
                  <td style={{ textAlign: "left" }}>{trade.symbol}</td>
                  <td className={getProfitLevel(trade.profit)}>
                    {parseInt(trade.profit)} {account.currency}
                  </td>
                  <td>{trade.trades}</td>
                  <td className={getDDPercentageLevel(((-1 * trade.currentDD) / account.accountSize) * 100)}>
                    {parseInt(trade.currentDD)} {account.currency}
                  </td>
                  <td>{trade.openTrades}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
